import Countries from './country.json';
export const getCoutriesData = () => {
    let _cntries = [];
    Countries.forEach((_country) => {
        _cntries.push({ "name": _country?.name, "id": _country?.iso2 });
    });
    return _cntries;
}

export const getStatesById = (iso2) => {
    let fetchStates = [];
    Countries.forEach((_country) => {
        if (_country?.iso2 === iso2) {
            _country?.states?.forEach((_stt) => {
                fetchStates.push({ "name": _stt?.name, "id": _stt?.state_code });
            })
        }
    });
    return fetchStates;
}