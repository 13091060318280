import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createCase, deleteCaseById, getCaseById, getCasesData, updateCase, assignUsers, getCaseAssignedUsers } from "../../../Services/API";
 

export const casesCreateSlice = createAsyncThunk("casesCreateSlice", async (data) => {
    try {
        const response = await createCase(data, data.header);
        return response;
    } catch (error) {
        throw error;
    }
});

export const getCaseByIdSlice = createAsyncThunk("getCaseByIdSlice", async (data) => {
    try {
        const response = await getCaseById(data, data.header);
        return response
    } catch (error) {
        throw error;
    }
});

export const getCaseAssignedUsersSlice = createAsyncThunk("getCaseAssignedUsersSlice", async(data) => {
    try {
        return await getCaseAssignedUsers(data, data.header);
    }
    catch(error) {
        throw error;
    }
});

export const getAllCasesSlice = createAsyncThunk("getAllCasesSlice", async (data) => {
    try {
        const response = await getCasesData(data, data.header);
        return response
    } catch (error) {
        throw error;
    }
});
export const caseDEleteSlice = createAsyncThunk("caseDEleteSlice", async (data) => {
    try {
        const response = await deleteCaseById(data, data.header);
        return response
    } catch (error) {
        throw error;
    }
});
export const casesUpdateSlice = createAsyncThunk("casesUpdateSlice", async (data) => {
    try {
        const response = await updateCase(data, data.header);
        return response
    } catch (error) {
        throw error;
    }
});

export const assignUsersToCase = createAsyncThunk("assignUsersToCase", async (data) => {
    try {
        const response = await assignUsers(data, data.header);
        return response
    } catch (error) {
        throw error;
    }
});

const initialState = {
    loading: false,
    error: null
}

export const casesDataSlice = createSlice({
    name: "casesDataSlice",
    initialState,
    reducers: {
        reset: (state, action) => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(casesCreateSlice.pending, (state) => {
            state.loading = true;
        }).addCase(casesCreateSlice.fulfilled, (state, action) => {
            state.loading = false;
            state.caselogindetail = action.payload;
        }).addCase(casesCreateSlice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }).addCase(getCaseByIdSlice.pending, (state) => {
            state.loading = true;
        }).addCase(getCaseByIdSlice.fulfilled, (state, action) => {
            state.loading = false;
            state.caselogindetail = action.payload;
        }).addCase(getCaseByIdSlice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }).addCase(casesUpdateSlice.pending, (state) => {
            state.loading = true;
        }).addCase(casesUpdateSlice.fulfilled, (state, action) => {
            state.loading = false;
            state.caselogindetail = action.payload;
        }).addCase(casesUpdateSlice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }).addCase(caseDEleteSlice.pending, (state) => {
            state.loading = true;
        }).addCase(caseDEleteSlice.fulfilled, (state, action) => {
            state.loading = false;
            state.caselogindetail = action.payload;
        }).addCase(caseDEleteSlice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }).addCase(getAllCasesSlice.pending, (state) => {
            state.loading = true;
        }).addCase(getAllCasesSlice.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        }).addCase(getAllCasesSlice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }).addCase(assignUsersToCase.pending, (state) => {
            state.loading = true;
        }).addCase(assignUsersToCase.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        }).addCase(assignUsersToCase.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

    },

})
export const { setSidebarOffcanvasShow } = casesDataSlice.actions
export default casesDataSlice.reducer;