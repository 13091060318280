import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccounts } from "../../../Services/API";
import { pageSize } from "../../../Utils/constants";

export const getAccountTable = createAsyncThunk("accountsSlice", async (data) => {
    try {
        const response = await getAccounts(data);
        return response
    } catch (error) {
        throw error;
    }
});


const initialState = {
    search: "",
    tableData: [],
    page: 1,
    pageSize: pageSize,
    status: "",
    totalPages: 0,
    totalCount: 0,
    startDate: null,
    endDate: null,
    loading: false,
    sortBy: "",
    sortOrder: "",
    pagination: {},
    payment_plan: "",
    error: null
}

export const accounttable = createSlice({
    name: "accounttable",
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPagesize: (state, action) => {
            state.pageSize = action.payload;
        },
        setSearch: (state, action) => {
            state.search = action?.payload;
        },
        setSortBy: (state, action) => {
            state.sortBy = action?.payload;
        },
        setSortOrder: (state, action) => {
            state.sortOrder = action?.payload;
        },
        setDateGlobal: (state, action) => {
            state.startDate = action?.payload?.formattedStartDate;
            state.endDate = action?.payload?.formattedEndDate;
        },
        setStatus: (state, action) => {
            state.status = action?.payload
        },
        setPaymentPlan: (state, action) => {
            state.payment_plan = action?.payload
        },
        reset: (state, action) => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(getAccountTable.pending, (state) => {
            state.loading = true;
        }).addCase(getAccountTable.fulfilled, (state, action) => {
            state.loading = false;
            state.tableData = action?.payload?.data;
            state.totalPages = action?.payload?.data?.data?.pagination?.totalPages
            state.pagination = action?.payload?.data?.data?.pagination
        }).addCase(getAccountTable.rejected, (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        })

    }
})
export const { setPage, setSearch, setPaymentPlan, setDateGlobal, setStatus, setSortBy, setSortOrder, setPagesize,reset } = accounttable.actions;
export default accounttable.reducer;