import axios from "axios";
import { getToken } from "./tokenService";

export const commonrequest = async (method, url, body, header, isJson = "json") => {
    let token = getToken();
    let config = {
        method: method,
        url: url,
        headers: {},
        data: body
    };

    if (isJson && header && header.Authorization) {
        config.headers.Authorization = header.Authorization;
    }
    else if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    if (header && isJson === "form") {
        config.headers["Content-Type"] = "multipart/form-data";
    }
    else {
        config.headers["Content-Type"] = "application/json";
    }
    try {
        const response = await axios(config);
        return response;
    }
    catch (error) {   
        if (error?.response?.status === 401 || error?.code === "ERR_NETWORK") {
            localStorage.removeItem("token");
        }
        return error;
    }
};
