import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    requestOtpApi,
    verifyOtpApi,
    forgotPassword,
    resetPassword
} from "../../../Services/API";

// user login  Slice
export const requestOtpSlice = createAsyncThunk("requestOtp", async (data) => {
    try {
        const response = await requestOtpApi(data);
        return response
    } catch (error) {
        throw error;
    }
});
export const verifyOtpSlice = createAsyncThunk("verifyOtp", async (data) => {
    try {
        const response = await verifyOtpApi(data);
        return response
    } catch (error) {
        throw error;
    }
});
export const forgetPasswordSlice = createAsyncThunk("forgetPasswordSlice", async (data) => {
    try {
        const response = await forgotPassword(data);
        return response
    } catch (error) {
        throw error;
    }
});
// deprecated for OTP
export const resetPasswordSlice = createAsyncThunk("resetPasswordSlice", async (data) => {
    try {
        const response = await resetPassword(data);
        return response
    } catch (error) {
        throw error;
    }
});

const initialState = {
    otpRequested: false,
    otpVerified: false,
    userlogindetail: [],
    userData: [],
    loading: false,
    error: null
};

export const AuthSlice = createSlice({
    name: "AuthSlice",
    initialState,
    extraReducers: (builder) => {
        // Request OTP flow
        builder.addCase(requestOtpSlice.pending, (state) => {
            state.loading = true;
            state.otpRequested = false;
        })
            .addCase(requestOtpSlice.fulfilled, (state, action) => {
                state.loading = false;
                state.otpRequested = true; // OTP request was successful
            })
            .addCase(requestOtpSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });

        // Verify OTP flow
        builder.addCase(verifyOtpSlice.pending, (state) => {
            state.loading = true;
            state.otpVerified = false;
        })
            .addCase(verifyOtpSlice.fulfilled, (state, action) => {
                state.loading = false;
                state.otpVerified = true; // OTP verified successfully
                state.userlogindetail = action.payload; // Store login details after OTP verification
            })
            .addCase(verifyOtpSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export default AuthSlice.reducer;