import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./slice/AuthSlice";
import usersTableReducer from "./slice/usersTableSlice";
import usersDataReducer from "./slice/usersDataSlice";
import casesDataReducer from "./slice/casesDataSlice";
import casesTableReducer from "./slice/casesTableSlice";
import commonReducer from "./slice/commonSlice";
import accountsDataReducer from "./slice/accountsDataSlice";
import accountsTableReducer from "./slice/accountsTableSlice";
import filesTableReducer from "./slice/filesTableSlice";
import biDashboardReducer from './slice/biDashboardSlice';

export const store = configureStore({
    reducer: {
        Auth: AuthReducer,
        users: usersDataReducer,
        usertable: usersTableReducer,
        cases: casesDataReducer,
        casetable: casesTableReducer,
        accounts: accountsDataReducer,
        accounttable: accountsTableReducer,
        common: commonReducer,
        files: filesTableReducer,
        biDashboard: biDashboardReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
