import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { generateYfLoginToken, getMe } from '../../../Services/API';

// Fetch MeInfo object (user and customer info)
export const fetchMeInfo = createAsyncThunk(
    'biDashboard/fetchMeInfo',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getMe();
            if (response && response.data) {
                return response.data;  // Contains both user and customer information
            } else {
                return rejectWithValue('Failed to fetch user and customer info');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Fetch Yellowfin login token
export const fetchYfLoginToken = createAsyncThunk(
    'biDashboard/fetchYfLoginToken',
    async (_, { rejectWithValue }) => {
        try {
            const response = await generateYfLoginToken();
            if (response && response.data) {
                return response.data;
            } else {
                return rejectWithValue('Failed to fetch login token');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const biDashboardSlice = createSlice({
    name: 'biDashboard',
    initialState: {
        yfLoginToken: null,
        yfLoginTokenId: null,
        loading: false,
        meLoading: false,
        error: null,
        currentUser: null,
        currentCustomer: null,
    },
    reducers: {
        resetState: (state) => {
            state.yfLoginToken = null;
            state.yfLoginTokenId = null;
            state.loading = false;
            state.meLoading = false;
            state.error = null;
            state.currentUser = null;
            state.currentCustomer = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // Fetch MeInfo (user and customer info)
            .addCase(fetchMeInfo.pending, (state) => {
                state.meLoading = true;
                state.error = null;
            })
            .addCase(fetchMeInfo.fulfilled, (state, action) => {
                state.currentUser = action.payload.user;
                state.currentCustomer = action.payload.customer;
                state.meLoading = false;
                state.error = null;
            })
            .addCase(fetchMeInfo.rejected, (state, action) => {
                state.meLoading = false;
                state.error = action.payload;
            })
            // Fetch Yellowfin login token
            .addCase(fetchYfLoginToken.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchYfLoginToken.fulfilled, (state, action) => {
                state.yfLoginToken = action.payload.securityToken;
                state.yfLoginTokenId = action.payload.tokenId;
                state.loading = false;
                state.error = null;
            })
            .addCase(fetchYfLoginToken.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { resetState } = biDashboardSlice.actions;
export default biDashboardSlice.reducer;
