import './App.scss';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './Redux/app/store';
import { Suspense, useEffect, useState } from 'react';
import NoInternet from "./Utils/NoInternet/index"
import * as LazyLoad from "./Utils/LazyLoader/LazyLoader";
import { Toaster } from 'react-hot-toast';
import 'react-tooltip/dist/react-tooltip.css'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const App = () => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initialization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine)
  }, [])

  // event listeners to update the state 
  window.addEventListener('online', () => {
    setOnline(true)
  });

  window.addEventListener('offline', () => {
    setOnline(false)
  });

  return (
    <>
      <BrowserRouter >
        <Provider store={store}>
          <Suspense>
            {isOnline ? <LazyLoad.CUSTOMROUTES /> : <NoInternet />}
          </Suspense>
        </Provider>
      </BrowserRouter>
      <Toaster />
    </>
  );
};

export default App;
