export const getToken = () => {
    try {
        const token = localStorage.getItem("token");
        if (!token) {
            throw new Error('no token found');
        }
        const payload = JSON.parse(atob(token.split(".")[1]));
        if (!payload) {
            throw new Error('token has no payload');
        }
        const expiry = payload.exp;
        if (!expiry) {
            throw new Error('token has no expiry');
        }
        const now = Math.floor(Date.now() / 1000);
        if (expiry < now) {
            throw new Error('token has expired');
        }
        return token;
    }
    catch (err) {
        console.error('error parsing token: ', err.message);
        localStorage.removeItem("token");
        return null;
    }
}
